<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 11:48:11
 * @LastEditTime: 2023-04-11 16:52:42
 * @Descripttion: 【首页】通知详情
-->
<style lang="scss" scoped>
*{
    box-sizing: border-box;
}
.notify-detail {
    @include innerPage;
    @include pageHead(-10px);
    .content{
		width: 100%;
		padding: 20px;
        height: calc(100vh - 280px);
		overflow: auto;
		.item{
			width: 100%;
			background-color: #FFFFFF;
			border-radius: 14px;
			padding: 16px 10px;
			margin-bottom: 10px;
			.h3{
				font-size: 18px;
				font-weight: bold;
				color: #222222;
				line-height: 30px;
				position: relative;
				padding-left: 30px;
				.txt{
					width: 20px;
					height: 20px;
					text-align: center;
					line-height: 20px;
					border-radius: 4px;
					font-size: 14px;
					position: absolute;
					left: 0;
					top: 0;
					color: #FFFFFF;
					&.type1{
						background: #01A1FD;
					}
					&.type2{
						background: #1DBB62;
					}
				}
			}
			.imglist{
				width: 100%;
				margin-top: 16px;
				display: inline-block;
				padding: 0 30px;
				.el-image{
					border-radius: 8px;
					margin: 10px 10px 0 0;
					float: left;
					&:nth-child(5n+5){
						margin-right: 0;
					}
				}
			}
			.date{
				padding: 0 30px;
				margin-top: 10px;
				height: 20px;
				font-size: 14px;
				font-weight: 400;
				color: #888888;
				line-height: 20px;
			}
		}
		.noReply{
			padding: 20px 0;
			text-align: center;
			font-size: 16px;
			font-weight: bold;
			color: #AAAAAA;
		}
	}
}
@media screen and (max-width: 1200px) {
  .bitmap {
    width: 140px !important;
  }
  .notify-detail .page-head{
	height: 130px !important;
  }
}
</style>

<template>
    <section class="notify-detail">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper">
                <breadcrumb />
                <el-button type="custom_primary" @click="$router.go(-1)" size="medium">返回</el-button>
            </div>
        </div>
        <div class="content" v-if="info.plfee_content">
			<div class="item">
				<div class="h3">
					<text class="txt type1">问</text>
					{{info.plfee_content}}
				</div>
				<div class="imglist" v-if="plfee_image.length>0">
					<div v-for="(item,index) in plfee_image" :key="index">
						<el-image v-if="item" style="width: 60px; height: 60px;" :src="item" :preview-src-list="plfee_image"></el-image>
					</div>
				</div>
				<div class="date">
					{{info.create_time}}
				</div>
			</div>
			<div class="item" v-if="info.plfee_status==20">
				<div class="h3">
					<text class="txt type2">答</text>
					<div class="parse" v-html="info.plfee_reply"></div>
				</div>
				<div class="date">
					{{info.update_time}}
				</div>
			</div>
			<div class="noReply" v-else>
				还未回复，请耐心等待...
			</div>
		</div>
    </section>
</template>

<script>
import { plastumessage_info } from "@api/home"
export default {
    name: 'home_notifyDetail',
    data() {
        return {
			info:{},
			plfee_image:[],
        }
    },
    created() {
        this.getDetail(this.$route.params.id)
    },
    methods: {
        async getDetail(id) {
            let { data } = await plastumessage_info({plstu_mes_id:id});
            this.info = data;
			this.plfee_image=data.plfee_image.length>0?data.plfee_image.split(','):[]
        }
    },
}
</script>